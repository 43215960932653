.bg-lightGray {
  background-color: #F7F7F8 !important;
}

.center {
  text-align: center !important;
}

.fp-white-logo-size {
  width: 12rem;
}

.bg-lightGray-card {
  background-color: #F7F7F8 !important;
  box-shadow: none !important;
}

.title-Left {
  text-align: start;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem !important;
  font-weight: bold;
}

.title {
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem !important;
  font-weight: bold;
}

.black-text {
  text-align: start;
  color: black !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem !important;
}

.Subtitle-Left {
  text-align: start;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
}

.Subtitle-Left-small {
  text-align: start;
  color: black !important;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
}

.Subtitle-Right {
  text-align: end;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
}

.subtitle-center {
  text-align: center;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
}

.subtitle {
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
}

.step-title {
  font-size: 2rem;
  color: #112D68;
  padding: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.Label-blue {
  text-align: start;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.text-blue {
  text-align: start;
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

.text-blue-medium {
  color: #112D68 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

label {
  text-align: start;
  color: #848484 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem !important;
}

button {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}

.button-blue {
  background-color: #112D68;
  color: #FFFFFF;
  border-color: #112D68;
  font-weight: 600 !important;
}

.button-orange {
  background-color: #FF9F43;
  color: #FFFFFF;
  border-color: #FF9F43;
  font-weight: 600 !important;
}

.search {
  width: 400px;
  border-radius: 5px;
  border-color: black;
}

.row-center {
  align-items: center;
}

.button-blue:hover {
  background-color: #112D68;
  /* Dark Blue */
  color: white;
}

.button-orage:hover {
  background-color: #FF9F43;
  /* Dark Blue */
  color: white;
}

.button-red:hover {
  background-color: #CC3B3B;
  /* Dark Red */
  color: #FFFFFF;
}

.button-red {
  background-color: #CC3B3B;
  color: #FFFFFF;
  border-color: #CC3B3B;
  font-weight: 600 !important;
}

.button-green {
  background-color: #70AC44;
  color: #FFFFFF;
  border-color: #70AC44;
  font-weight: 600 !important;
}

.button-green:hover {
  background-color: #70AC44;
  color: #FFFFFF;
}

.button-white {
  background-color: #fff;
  color: #6FAB44;
  border-color: green;
  font-weight: 600 !important;
}

.button-white:hover {
  background-color: #fff;
  color: #40751B;
  border-color: inherit;
  font-weight: 600 !important;
}

.button-gray {
  background-color: #70AC44;
  color: #FFFFFF;
  border-color: #70AC44;
  font-weight: 600 !important;
}

.button-gray:hover {
  background-color: #70AC44;
  color: #FFFFFF;
  border-color: #70AC44;
  font-weight: 600 !important;
}

.container {
  background-color: #fff;
  border: 1px solid #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 2rem;
  margin: auto;
}

.BgBlue {
  background-color: #112D68;
}

.FpBlue {
  color: #112D68;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #112D68;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #112D68;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

input {
  background-color: #fff;
  color: #112D68;
  border: 0.12rem solid #112D68;
  font-family: "Montserrat", sans-serif;
}

input[type=text]:focus {
  border: 0.12rem solid #112D68;
  background-color: #fff;
}

.ng-select .ng-select-container {
  border-color: #112D68 !important;
}

.ng-select.search-border .ng-select-container {
  border: none;
  min-height: 36px;
  align-items: center;
}

.ng-select.search-border .ng-option {
  padding: 0 !important;
}

.ng-select.search-border.ng-select-focused:not(.ng-select-closed) > .ng-select-container {
  border: none;
}

.ng-select.search-border.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border: none;
}

.ng-select.payitem .ng-select-container {
  border-color: #112D68 !important;
  background-color: #f5f7fa !important;
}

.ng-select.ng-select-focused:not(.ng-select-closed) > .ng-select-container {
  border-color: #112D68 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #112D68 !important;
}

.drag-and-drop {
  border-color: #112D68 !important;
  color: #112D68 !important;
}

.circle {
  display: inline-block;
  background-color: #112D68;
  border-radius: 50%;
}

.circle-inner {
  color: white !important;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 30px;
  width: 30px;
  font-size: 12px;
}

.circle-white {
  display: inline-block;
  background-color: white !important;
  border-radius: 50%;
  box-shadow: 0px 0px 12px 0px rgba(75, 67, 86, 0.23);
}

.circle-inner-blue {
  color: #112D68 !important;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 30px;
  width: 30px;
  font-weight: bold;
  font-size: 16px;
}

.ng-select.ddcustom .ng-select-container {
  border: none !important;
}

.label-dark-blue {
  text-align: start;
  color: #0A3542 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.grid-icon {
  font-size: 1rem !important;
  cursor: pointer !important;
  color: #112D68 !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  border-color: #e0e0e0 !important;
}

.form-control {
  background-color: white;
}

.border-blue {
  border: 1.5px solid #112D68 !important;
  border-radius: 5px;
}

.menu-item {
  background-color: transparent;
  margin: 1%;
  border: transparent;
  color: #112D68;
  width: 90%;
  box-shadow: none;
  text-align: left;
}

.menu-item-clicked {
  color: #fff;
  margin: 1%;
  border: transparent;
  width: 90%;
  background-color: #112D68;
  box-shadow: none;
  text-align: left;
  border-radius: 5px;
}

.light-green {
  background-color: #BDF8BF;
  color: #70AC44;
}

.dark-green {
  background-color: #70AC44;
  color: #FFFFFF;
}

.light-blue {
  background-color: #88C2D3;
  color: #176982;
}

.dark-blue-color {
  background-color: #112D68;
  color: #FFFFFF;
}

.orange {
  background-color: #FEEFD0;
  color: #F77E17;
}

.light-yellow {
  background-color: #FFDFAE;
  color: #BC822A;
}

.light-red {
  background-color: #FCC9C9;
  color: #CC3B3B;
}

.dark-yellow {
  background-color: #FFC107;
  color: #FFFFFF;
}

.dark-red {
  background-color: #CC3B3B;
  color: #FFFFFF;
}

.light-grey {
  background-color: #E9E9E9;
  color: #444444;
}

.dark-grey {
  background-color: #8F8F8F;
  color: #FFFFFF;
}

.purple {
  background-color: #511A66;
  color: #FFFFFF;
}

.purple:hover {
  background-color: #511A66;
  color: #FFFFFF;
  border-color: #511A66;
  font-weight: 600 !important;
}

.dark-red:hover {
  background-color: #CC3B3B;
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-weight: 600 !important;
}

.dark-yellow:hover {
  background-color: #FFC107;
  color: #FFFFFF;
  border-color: #FFC107;
  font-weight: 600 !important;
}

.dark-grey:hover {
  background-color: #8F8F8F;
  color: #FFFFFF;
  border-color: #8F8F8F;
  font-weight: 600 !important;
}

.dark-blue:hover {
  background-color: #112D68;
  color: #FFFFFF;
  border-color: #112D68;
  font-weight: 600 !important;
}

.dark-green:hover {
  background-color: #70AC44;
  color: #FFFFFF;
  border-color: #70AC44;
  font-weight: 600 !important;
}