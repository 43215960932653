/* You can add global styles to this file, and also import other style files */
.title-Left {
    text-align: start;
    font-size: 1.8rem !important;
    color: #1C6983 !important;
}

.lock-screen-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}



.myCustomModalClass .modal-dialog {
    max-width: 1000px;
    /* margin: 100 auto; */

}

.myCustomModalClass1 .modal-dialog {
    max-width: 600px;
    /* margin: 100 auto; */

}

.myCustomModalClassWithGrid .modal-dialog {
    max-width: 1200px;
    /* margin: 100 auto; */

}

.myCustomModalClass .modal-dialog  .modal-content {
  
}

.myCustomModalClass1 .modal-dialog  .modal-content {
  
}
.add-payroll-modal{
    z-index: 0;
}

.toast-message-rtl{

    text-align: right;

    direction: rtl;

    white-space: pre-line;

}

.toast-message-ltr{

    text-align: left;

    direction: ltr;

    white-space: pre-line;

}

.red-border {
    border: 1px solid red !important;
  }

/* .datatable-scroll{
    display: inherit !important;
}  */

.ChangeComparisonToolTipLTR {
    float: right;
    color: rgb(112, 173, 71);
    /* display: none; */
    width: 27px;
    height: 27px;
    font-size: 17px;
    text-align: center;
    padding: 3px
}

.ChangeComparisonToolTipRTL {
    float: left;
    color: rgb(112, 173, 71);
    /* display: none; */
    width: 27px;
    height: 27px;
    font-size: 17px;
    text-align: center;
    padding: 3px
}


.ngbTooltipCostumClass {
    opacity: 1 !important;

}

.ngbTooltipCostumClass .tooltip-inner {
    background-color: white;
    background: white;
    color: #136882;
    border: 1px solid rgb(112, 173, 71);
    font-size: 14px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 3000px;
    min-width: 320px;
    text-align: left;
    opacity: 1 !important;
}

.ngbTooltipCostumClass .tooltip-show {
    opacity: 1 !important;
}

.ngbTooltipCostumClass .arrow::before {
    background-color: white;
    background: white;
    color: rgb(112, 173, 71);
    border-top-color: rgb(112, 173, 71);

}
