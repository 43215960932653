@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import '../../../node_modules/@swimlane/ngx-datatable/index.css';
/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
@import '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: rgb(170, 209, 249);
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: rgb(16, 108, 200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/* wizard */
.step-title {
  font-size: 2rem;
  color: #0A3542;
  padding: 1rem;
}

.flx-wizard aw-wizard-navigation-bar.horizontal ul.steps-indicator li:after {
  font-weight: bold;
}
.flx-wizard aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current div a {
  color: #136882 !important;
}
.flx-wizard aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current:after {
  border-color: #136882 !important;
  background-color: #136882 !important;
  color: white !important;
}

.flx-wizard aw-wizard-navigation-bar.horizontal ul.steps-indicator li div a {
  text-transform: none;
}
.flx-wizard aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done:not(:last-child):before {
  background-color: #136882 !important;
  height: 0.2rem;
}
.flx-wizard aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing:after {
  color: white !important;
  background-color: #136882 !important;
  border-color: #cdcdcd !important;
}
.flx-wizard aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done:after {
  color: #136882 !important;
  background-color: white !important;
  border: 2px solid #136882;
}
.flx-wizard aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.optional:after {
  color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}

.red-row {
  color: red !important;
}

.green-row {
  color: green !important;
}

.ngx-datatable.bootstrap.core-bootstrap.height-70-vh {
  height: 70vh !important;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
  background-color: #F7F7F8;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell {
  border-bottom: 0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row {
  border-color: #E0E0E0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.datatable-row-even {
  background-color: gray;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.active {
  background-color: #112D68;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.active i {
  color: #fff !important;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell-label .cell-line-height {
  line-height: 1.25;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell {
  display: flex;
  align-items: center;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer {
  background-color: white !important;
  color: #342E49;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li {
  margin: 0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:first-child a {
  margin: 0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:first-child a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:last-child a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:nth-child(2) a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:nth-last-child(2) a {
  padding: 1.1rem 1.035rem;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:first-child a i, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:last-child a i, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:nth-child(2) a i, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:nth-last-child(2) a i {
  position: absolute;
  top: 0.55rem;
  left: 0.4rem;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li.disabled a {
  color: #757575;
  background-color: #E0E0E0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:not(.disabled) a {
  color: #112D68;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:not(.disabled) a:hover {
  background-color: #EEEEEE !important;
  border-color: #E0E0E0;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:not(.disabled).active a {
  z-index: 3;
  color: #fff;
  background-color: #112D68 !important;
  border-color: #112D68;
  font-weight: inherit;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li:not(.disabled):hover a {
  background-color: #F5F5F5;
  font-weight: inherit;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li a {
  position: relative;
  display: block;
  padding: 1.1rem 0.75rem;
  margin: 0 0 0 5px;
  line-height: 0.1;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 0.35rem;
  font-size: 15px;
}
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager li a i {
  padding: 0;
}
.ngx-datatable.bootstrap.core-bootstrap.header-height-50 .datatable-header {
  height: 50px !important;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  line-height: 24px;
}

@media (max-width: 768px) {
  .page-count {
    display: none;
  }
  .datatable-pager {
    text-align: center !important;
    margin: 0 !important;
  }
}
@media screen and (max-width: 800px) {
  .desktop-hidden {
    display: initial;
  }
  .mobile-hidden {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none;
  }
  .mobile-hidden {
    display: initial;
  }
}
html body.layout-dark .datatable-footer .pager li.disabled a {
  color: #626164;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-header {
  background-color: #101010;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-body-row {
  border-color: #474748;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-footer {
  color: #B2B1B5;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-footer .pager li.disabled a,
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-footer .pager li a {
  background-color: #1E1E1E;
  border-color: #474748;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-footer .pager li:not(.disabled):hover a {
  background-color: #1E1E1E;
}
html body.layout-dark:not(.layout-transparent) .ngx-datatable.bootstrap .datatable-footer .pager li:not(.disabled):not(.active) a:hover {
  background-color: #1E1E1E !important;
  border-color: #474748 !important;
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-header {
  background-color: rgba(0, 0, 0, 0.12);
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-body-row {
  border-color: rgba(238, 238, 238, 0.2);
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer {
  color: #D6D5D8;
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer .pager li.disabled a,
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer .pager li a {
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer .pager li:not(.disabled) a {
  color: #D6D5D8;
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer .pager li:not(.disabled):hover a {
  background-color: rgba(0, 0, 0, 0.12);
}
html body.layout-dark.layout-transparent .ngx-datatable.bootstrap .datatable-footer .pager li:not(.disabled):not(.active) a:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.modal-backdrop.show {
  z-index: 999 !important;
}

.title {
  font-size: 2rem;
  color: #112D68;
  padding: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.datatable-header-cell-label {
  font-size: 1rem;
  color: #0A3542;
  font-weight: bold;
}

.datatable-icon-sort-unset {
  font-size: 1rem;
  color: #0A3542;
  font-weight: bold;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
  background-color: white !important;
}

.datatable-row-even {
  background-color: #EFEFEF !important;
}

.datatable-row-odd {
  background-color: white !important;
}

.datatable-body .datatable-row-left {
  background-color: inherit;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  background-color: white !important;
}

[dir=ltr] .button-icon-padding {
  padding-right: 0.375rem !important;
  padding-left: 0rem !important;
}

.padding-right {
  margin-right: 0.375rem !important;
}

[dir=ltr] .float-right1 {
  float: right;
}

[dir=ltr] .radio-button-margin {
  margin-right: 0.375rem !important;
}

[dir=ltr] .ng-dropdown-panel .scroll-host {
  direction: ltr !important;
  text-align: start !important;
}

.app-sidebar .navigation .menu-title {
  margin: 0;
  font-size: 0.86rem;
  font-weight: 400;
  letter-spacing: 0.6px;
  opacity: 1;
  height: auto;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.logo-margin {
  margin-right: 0px !important;
}

[dir=ltr] .img-padding {
  padding-right: 0px !important;
}

[dir=ltr] .button-margin {
  margin-left: 0.375rem !important;
  margin-right: 0px !important;
}

[dir=ltr] .font-search {
  float: left;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: -33px;
  position: relative;
  z-index: 2;
}

.ngx-datatable input[type=checkbox] {
  display: block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}