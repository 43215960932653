@import "../core/variables/variables";
@import "../core/variables/app-variables";
@import "../core/colors/palette-variables";

// Full page background
.auth-page:not(.layout-dark) {
 // background-color: rgb(21, 105, 129);
  background-image: linear-gradient(43deg, rgba(105, 105, 105, 0.02) 0%, rgba(105, 105, 105, 0.02) 62%, rgba(227, 227, 227, 0.02) 62%, rgba(227, 227, 227, 0.02) 69%, rgba(24, 24, 24, 0.02) 69%, rgba(24, 24, 24, 0.02) 80%, rgba(13, 13, 13, 0.02) 80%, rgba(13, 13, 13, 0.02) 82%, rgba(13, 13, 13, 0.02) 82%, rgba(13, 13, 13, 0.02) 100%), linear-gradient(31deg, rgba(155, 155, 155, 0.02) 0%, rgba(155, 155, 155, 0.02) 29%, rgba(164, 164, 164, 0.02) 29%, rgba(164, 164, 164, 0.02) 41%, rgba(200, 200, 200, 0.02) 41%, rgba(200, 200, 200, 0.02) 74%, rgba(229, 229, 229, 0.02) 74%, rgba(229, 229, 229, 0.02) 79%, rgba(15, 15, 15, 0.02) 79%, rgba(15, 15, 15, 0.02) 100%), linear-gradient(319deg, rgba(39, 39, 39, 0.02) 0%, rgba(39, 39, 39, 0.02) 17%, rgba(49, 49, 49, 0.02) 17%, rgba(49, 49, 49, 0.02) 25%, rgba(59, 59, 59, 0.02) 25%, rgba(59, 59, 59, 0.02) 32%, rgba(194, 194, 194, 0.02) 32%, rgba(194, 194, 194, 0.02) 46%, rgba(220, 220, 220, 0.02) 46%, rgba(220, 220, 220, 0.02) 100%), linear-gradient(113deg, rgba(188, 188, 188, 0.02) 0%, rgba(188, 188, 188, 0.02) 15%, rgba(223, 223, 223, 0.02) 15%, rgba(223, 223, 223, 0.02) 46%, rgba(21, 21, 21, 0.02) 46%, rgba(21, 21, 21, 0.02) 88%, rgba(93, 93, 93, 0.02) 88%, rgba(93, 93, 93, 0.02) 94%, rgba(130, 130, 130, 0.02) 94%, rgba(130, 130, 130, 0.02) 100%), linear-gradient(29deg, rgba(134, 134, 134, 0.02) 0%, rgba(134, 134, 134, 0.02) 27%, rgba(181, 181, 181, 0.02) 27%, rgba(181, 181, 181, 0.02) 41%, rgba(81, 81, 81, 0.02) 41%, rgba(81, 81, 81, 0.02) 46%, rgba(253, 253, 253, 0.02) 46%, rgba(253, 253, 253, 0.02) 58%, rgba(74, 74, 74, 0.02) 58%, rgba(74, 74, 74, 0.02) 100%), linear-gradient(90deg, rgb(229, 229, 229), rgb(228, 228, 228));
  background-size: cover;
  background-repeat: no-repeat;
}

.auth-page {
  .auth-img {
    padding: 0;

    .auth-img-bg {
      background-color: map-get($grey-color, lighten-4);
    }
  }
}

// to remove scrollbar in auth pages
.auth-height {
  overflow: auto;
}
