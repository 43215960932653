[dir=rtl] .ps-scrollbar-y-rail {
  left: 3px !important;
  right: auto !important;
}

[dir=rtl] .datatable-header-cell {
  text-align: right;
}
[dir=rtl] .ngx-datatable .datatable-header .datatable-header-cell {
  text-align: right;
}
[dir=rtl] .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: right;
  direction: ltr !important;
}
[dir=rtl] .ngx-datatable .empty-row {
  text-align: right;
}
[dir=rtl] .ngx-datatable .loading-row {
  text-align: right;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager {
  text-align: left;
}

[dir=rtl] .ct-label.ct-horizontal.ct-end {
  align-items: flex-end;
  justify-content: flex-end;
}

[dir=rtl] .ngx-datatable .datatable-footer .selected-count .page-count {
  text-align: right !important;
}
[dir=rtl] .page-count {
  text-align: right !important;
}

[dir=rtl] .datatable-body-cell {
  justify-content: end;
}

[dir=rtl] .navbar {
  padding-right: 20px;
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  [dir=rtl] .navbar .navbar-header {
    padding-right: 230px;
  }
  [dir=rtl] .navbar .navbar-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

[dir=rtl] .input-group > .form-control:not(:first-child),
[dir=rtl] .input-group > .input-group-append > .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}
[dir=rtl] .input-group > .input-group-prepend > .input-group-text,
[dir=rtl] .input-group > .form-control:not(:last-child),
[dir=rtl] .input-group > .custom-select:not(:last-child) {
  border-radius: 0 0.25rem 0.25rem 0;
}
[dir=rtl] .form-check {
  padding-right: 1.25rem;
}
[dir=rtl] .form-check-input {
  margin-right: -1.25rem;
}
[dir=rtl] .intl-tel-input {
  direction: ltr;
}
[dir=rtl] [dir=rtl] ul h4,
[dir=rtl] [dir=rtl] ul li {
  text-align: left !important;
}

.wrapper[dir=rtl].nav-collapsed .navbar .navbar-header {
  padding-right: 50px;
  padding-left: auto;
}
.wrapper[dir=rtl].nav-collapsed .main-panel .main-content {
  padding-right: 60px;
  padding-left: auto;
}
.wrapper[dir=rtl].nav-collapsed footer {
  padding-right: 60px;
  padding-left: auto;
}

[dir=rtl] .content-header {
  text-align: right;
}
[dir=rtl] .content-sub-header {
  text-align: right;
}
[dir=rtl] .wrapper.nav-collapsed .navbar .navbar-header {
  padding-right: 50px;
  padding-left: auto;
}
[dir=rtl] .wrapper.nav-collapsed .main-panel .main-content {
  padding-right: 60px;
  padding-left: auto;
}
[dir=rtl] .wrapper.nav-collapsed footer {
  padding-right: 60px;
  padding-left: auto;
}
[dir=rtl] .bootstrap-navbar {
  right: 0;
  left: auto;
}
[dir=rtl] .app-sidebar,
[dir=rtl] .off-canvas-sidebar {
  right: 0;
  left: auto;
}
[dir=rtl] .app-sidebar.hide-sidebar,
[dir=rtl] .off-canvas-sidebar.hide-sidebar {
  transform: translate3d(100%, 0, 0);
}
[dir=rtl] .app-sidebar .logo-img,
[dir=rtl] .off-canvas-sidebar .logo-img {
  margin-left: 15px;
  margin-right: auto;
}
[dir=rtl] .app-sidebar .logo-tim,
[dir=rtl] .off-canvas-sidebar .logo-tim {
  float: right;
}
[dir=rtl] .app-sidebar .navigation,
[dir=rtl] .off-canvas-sidebar .navigation {
  padding-right: 0;
  padding-left: auto;
}
[dir=rtl] .app-sidebar .navigation li.has-sub > a:after,
[dir=rtl] .off-canvas-sidebar .navigation li.has-sub > a:after {
  left: 15px;
  right: auto;
}
[dir=rtl] .app-sidebar .navigation li > a,
[dir=rtl] .off-canvas-sidebar .navigation li > a {
  padding: 10px 14px 10px 30px;
  margin: 5px 0;
  text-align: right;
}
[dir=rtl] .app-sidebar .navigation li > a:before,
[dir=rtl] .off-canvas-sidebar .navigation li > a:before {
  right: -100%;
  left: auto;
  border-left: solid 5px theme-color("danger");
  border-right: 0px;
}
[dir=rtl] .app-sidebar .navigation li.active > a:before,
[dir=rtl] .off-canvas-sidebar .navigation li.active > a:before {
  right: 0;
  left: auto;
}
[dir=rtl] .app-sidebar .navigation li ul li a,
[dir=rtl] .off-canvas-sidebar .navigation li ul li a {
  padding: 8px 60px 8px 18px;
}
[dir=rtl] .app-sidebar .navigation li ul li ul li a,
[dir=rtl] .off-canvas-sidebar .navigation li ul li ul li a {
  padding: 8px 70px 8px 18px;
}
[dir=rtl] .app-sidebar .navigation .badge.badge-pill,
[dir=rtl] .off-canvas-sidebar .navigation .badge.badge-pill {
  left: 28px;
  right: auto;
}
[dir=rtl] .app-sidebar .navigation i,
[dir=rtl] .off-canvas-sidebar .navigation i {
  float: right;
  margin-left: 10px;
  margin-right: auto;
}
[dir=rtl] .app-sidebar .sidebar-background,
[dir=rtl] .off-canvas-sidebar .sidebar-background {
  right: 0;
  left: auto;
}
[dir=rtl] .app-sidebar .logo p,
[dir=rtl] .off-canvas-sidebar .logo p {
  float: right;
}
[dir=rtl] .app-sidebar .logo .logo-text,
[dir=rtl] .off-canvas-sidebar .logo .logo-text {
  text-align: right;
}
[dir=rtl] .app-sidebar .logo-tim,
[dir=rtl] .off-canvas-sidebar .logo-tim {
  float: right;
}
[dir=rtl] .app-sidebar:after, [dir=rtl] .app-sidebar:before,
[dir=rtl] .off-canvas-sidebar:after,
[dir=rtl] .off-canvas-sidebar:before {
  right: 0;
  left: auto;
}
[dir=rtl] .sidebar-header a.nav-toggle {
  left: 30px;
  right: auto;
}
[dir=rtl] .sidebar-header a.nav-close {
  left: 30px;
  right: auto;
}
[dir=rtl] .main-panel {
  float: left;
}

@media (min-width: 1200px) {
  [dir=rtl] .vertical-menu .navbar {
    margin-left: 0 !important;
  }
}

[dir=rtl] .row {
  text-align: right;
}
[dir=rtl] .logo-flexx {
  justify-content: space-around;
}
[dir=rtl] .vertical-layout .main-panel .main-content {
  margin-right: 60px !important;
}

[dir=rtl] .text-right {
  text-align: left !important;
}
[dir=rtl] .text-left {
  text-align: right !important;
}
[dir=rtl] .iti__country-list--dropup {
  left: 0% !important;
  direction: ltr !important;
}
[dir=rtl] .iti__country-list {
  left: 0% !important;
}
[dir=rtl] .modal-open .modal {
  direction: rtl !important;
}
[dir=rtl] .myCustomModalClass .modal-dialog .modal-content {
  direction: rtl !important;
}
[dir=rtl] .myCustomModalClass1 .modal-dialog .modal-content {
  direction: rtl !important;
}

[dir=rtl] .button-icon-padding {
  padding-left: 0.375rem !important;
  padding-right: 0rem !important;
}

[dir=rtl] .padding-right {
  margin-right: 0rem !important;
}

[dir=rtl] .float-right1 {
  float: left;
}

[dir=rtl] .logo-margin {
  margin-left: 0 !important;
  padding-left: 0px !important;
  padding-right: 6px !important;
}

[dir=rtl] .img-padding {
  padding-left: 0px !important;
}

[dir=rtl] .button-margin {
  margin-left: 0px !important;
  margin-right: 0.375rem !important;
}

[dir=rtl] .font-search {
  float: right;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: -33px;
  position: relative;
  z-index: 2;
}

[dir=rtl] .multiselect-item-checkbox div {
  inline-size: fit-content;
}

[dir=rtl] .radio-button-margin {
  margin-left: 0.375rem !important;
}

[dir=rtl] .ng-dropdown-panel .scroll-host {
  direction: rtl !important;
  text-align: start !important;
}