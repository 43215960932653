// content overlay
.wrapper {
  &.show-overlay {
    .content-overlay {
      z-index: 16;
      // z-index: 3;
      opacity: 10;
    }
  }
  .content-overlay {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: calc(100% + 54px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.6);
    cursor: pointer;
    transition: all 0.7s;
    z-index: -1;
    visibility: visible;
  }
}
